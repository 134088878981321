import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import Layout from "../components/common/Layout";
import Hero from "../components/home/Hero";
import ContactUs from "../components/common/ContactUs";
import Intro from "../components/home/Intro";
import Benefits from "../components/home/Benefits";
import Features from "../components/home/Features";
import Seo from "../components/default/seo";

function IndexPage({ data }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <Layout>
      <Seo
        title={t("heroTitle")}
        description={t("heroSubtitle")}
        lang={language}
      />
      <Hero />
      <Intro />
      <Benefits />
      <Features />
      <ContactUs />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { in: ["common", "home"] } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IndexPage;
