import React from "react";
import { Link } from "gatsby";
import { Trans } from "react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function FeatureSection({
  feature: { title, subtitle, text, img, link },
}) {
  return (
    <div className="group mb-24 grid grid-cols-1 gap-16 lg:grid-cols-2">
      <div className="flex flex-col justify-center space-y-4 lg:group-even:order-last">
        <p className="uppercase text-primaryGreen">{subtitle}</p>
        <h2 className="text-4xl">{title}</h2>
        <p className="font-thin">{text}</p>
        <Link to={link}>
          <button
            className="
            mt-8 block border-2 border-primaryBlue px-6 py-2 font-bold text-primaryBlue
            transition-all hover:scale-105 hover:bg-primaryBlue/5"
          >
            <Trans>Learn More</Trans>
          </button>
        </Link>
      </div>
      <div className="relative h-48 md:h-96">
        <GatsbyImage
          alt="Alt"
          image={getImage(img)}
          layout="fullWidth"
          className="absolute h-full w-full object-cover"
        />
      </div>
    </div>
  );
}
