import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BenefitCard from "./BenefitCard";
import { useTranslation } from "react-i18next";
import {
  CalendarIcon,
  DatabaseIcon,
  EmojiHappyIcon,
  ScaleIcon,
} from "@heroicons/react/outline";

const iconStyles = "mx-auto block h-8 w-8 mb-4";

export default function Benefits() {
  const { t } = useTranslation();
  const data = useStaticQuery(benefitsQuery);

  const cards = [
    {
      title: t("satisfactionTitle"),
      text: t("satisfactionText"),
      img: data.satisfactionImage,
      icon: <EmojiHappyIcon className={iconStyles} />,
    },
    {
      title: t("scheduleTitle"),
      text: t("scheduleText"),
      img: data.scheduleImage,
      icon: <CalendarIcon className={iconStyles} />,
    },
    {
      title: t("complianceTitle"),
      text: t("complianceText"),
      img: data.complianceImage,
      icon: <ScaleIcon className={iconStyles} />,
    },
    {
      title: t("dataTitle"),
      text: t("dataText"),
      img: data.dataImage,
      icon: <DatabaseIcon className={iconStyles} />,
    },
  ];

  return (
    <div className="mx-auto mb-32 grid max-w-4xl grid-cols-1 lg:grid-cols-2">
      {cards.map((card) => (
        <BenefitCard key={card.title} card={card} />
      ))}
    </div>
  );
}

const benefitsQuery = graphql`
  query {
    satisfactionImage: file(
      relativePath: { regex: "/home/satisfaction.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    dataImage: file(relativePath: { regex: "/home/data.jpg/" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    scheduleImage: file(relativePath: { regex: "/home/schedule.jpg/" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    complianceImage: file(relativePath: { regex: "/home/compliance.jpg/" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
