import React from "react";
import logo from "../../images/logo.png";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "react-i18next";

export default function Intro() {
  const { t } = useTranslation();
  return (
    <div
      id="intro"
      className="mx-auto mb-40 flex flex-col items-start justify-start space-y-12 px-8 md:items-center md:justify-center lg:max-w-3xl"
    >
      <div className="flex w-full items-center justify-center">
        <StaticImage
          src="../../images/logo.png"
          alt="Cultiware Logo"
          className="block"
          placeholder="none"
        />
      </div>
      <h1 className="text-center text-4xl">{t("introTitle")} </h1>
      <p className="whitespace-pre-line text-lg font-thin text-textBlack md:text-center">
        {t("introText")}
      </p>
    </div>
  );
}
