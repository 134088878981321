import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "react-i18next";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default function Hero() {
  const { t } = useTranslation();

  return (
    <div className="mb-32">
      <div className="relative">
        <div className="relative z-10 flex h-[80vh] flex-col items-center justify-center gap-12 px-4">
          <h1 className="text-center text-6xl text-white md:max-w-lg">
            {t("heroTitle")}
          </h1>
          <p className="text-center font-bold text-white md:max-w-lg">
            {t("heroSubtitle")}
          </p>
        </div>
        <div className="absolute inset-0 h-full w-full object-cover">
          <StaticImage
            src="../../images/home/hero.jpg"
            alt="Cultiware Hero Image"
            layout="fullWidth"
            loading="eager"
            className="h-[80vh]"
            placeholder="none"
          />
        </div>

        <div className="absolute bottom-12 z-[999] w-full">
          <AnchorLink
            to="/#intro"
            title="Learn More About Us"
            className=" mx-auto flex items-center justify-center text-5xl
         text-white transition-all hover:scale-150"
          >
            <ion-icon name="arrow-down-outline"></ion-icon>
          </AnchorLink>
        </div>
      </div>
    </div>
  );
}
