import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import FeatureSection from "./FeatureSection";

export default function Features() {
  const { t } = useTranslation();
  const data = useStaticQuery(benefitsQuery);

  const features = [
    {
      title: t("s1Title"),
      subtitle: t("s1Subtitle"),
      text: t("s1Text"),
      img: data.controlsImage,
      link: "/solutions/",
    },
    {
      title: t("s2Title"),
      subtitle: t("s2Subtitle"),
      text: t("s2Text"),
      img: data.agricultureImage,
      link: "/solutions/",
    },
    {
      title: t("s3Title"),
      subtitle: t("s3Subtitle"),
      text: t("s3Text"),
      img: data.interoperabilityImage,
      link: "/solutions/",
    },
  ];

  return (
    <div className="mx-auto max-w-6xl px-8 md:px-32">
      {features.map((feature) => (
        <FeatureSection key={feature.title} feature={feature} />
      ))}
    </div>
  );
}

const benefitsQuery = graphql`
  query {
    controlsImage: file(relativePath: { regex: "/controls.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    agricultureImage: file(relativePath: { regex: "/agriculture.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    interoperabilityImage: file(
      relativePath: { regex: "/interoperability.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
