import React, { useEffect } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import clsx from "clsx";
import Fade from "react-reveal/Fade";
import TransitionGroup from "react-transition-group/TransitionGroup";
import { Trans } from "react-i18next";
import { Link } from "gatsby";
import useMobileBreakpoint from "../../hooks/useMobileBreakpoint";

export default function BenefitCard({ card: { img, title, text, icon } }) {
  const isMobile = useMobileBreakpoint();
  const [showDetail, setShowDetail] = React.useState(false);

  return (
    <div
      className={clsx(
        "relative flex h-[40rem] items-center justify-center md:h-[32rem] lg:h-96"
      )}
    >
      <TransitionGroup appear component={null} exit>
        <div
          onMouseEnter={() => setShowDetail(true)}
          onMouseLeave={() => setShowDetail(false)}
          className={clsx(
            "group relative z-20 flex h-full w-full flex-col items-center justify-center px-16 py-1 text-white",
            "hover:from-primaryBlue/70 hover:to-primaryGreen/70 lg:hover:bg-gradient-to-b"
          )}
        >
          <Fade cascade duration={240} collapse>
            <div>
              {icon}
              <h5 className="relative z-10 text-center text-3xl lg:text-2xl">
                {title}
              </h5>
            </div>
          </Fade>
          <Fade when={showDetail || isMobile} cascade duration={240} collapse>
            <div className="relative z-10 mt-4 text-center text-xl lg:text-base">
              <div>{text}</div>
            </div>
          </Fade>
          <Fade when={showDetail && !isMobile} cascade duration={240} collapse>
            <Link to="/solutions/">
              <button className="relative z-10 mx-auto mt-4 block border border-white py-2 px-8 text-white hover:bg-white hover:bg-opacity-10">
                <Trans>Learn More</Trans>
              </button>
            </Link>
          </Fade>
        </div>
      </TransitionGroup>
      <div className="absolute h-full w-full object-cover">
        <GatsbyImage
          alt="Alt"
          image={getImage(img)}
          layout="fullWidth"
          className="h-full w-full object-cover"
        />
      </div>
    </div>
  );
}
